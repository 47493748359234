import PaginationData from "@/models/general/PaginationData";
import ContractForm from "./ContractForm";
import ContractFormsFilter from "./ContractFormsFilter";

export default class ContractForms {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.contractFormsData = [];
    this.contractForm = new ContractForm();
    this.filterData = new ContractFormsFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.contractFormsPagination);
    this.contractFormsData = data.contractFormsPagination.contractFormsData;
  }
}
