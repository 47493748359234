<template>
  <div class="">
    <form autocomplete="off">
      <div class="custom-tab-card">
        <div class="my-card">
          <div class="row">
            <CustomSelectBox
              :className="'col-md-6'"
              :id="'employeeToken'"
              :value="employeeWorkContractData.employeeToken"
              :options="employeeTokenOptions"
              v-on:changeValue="employeeWorkContractData.employeeToken = $event"
              :title="$t('selectEmployee')"
              :imgName="'employees.svg'"
            />
            <CustomSelectBox
              :className="'col-md-6'"
              :id="'employeeJobToken'"
              :value="employeeWorkContractData.employeeJobToken"
              :options="jobTokenOptions"
              v-on:changeValue="
                employeeWorkContractData.employeeJobToken = $event
              "
              :title="$t('selectJobs')"
              :imgName="'jobs.svg'"
            />
            <CustomSelectBox
              :className="'col-md-6'"
              :id="'contractFormToken'"
              :value="employeeWorkContractData.contractFormToken"
              :options="contractFormTokenOptions"
              v-on:changeValue="
                employeeWorkContractData.contractFormToken = $event
              "
              :title="$t('ContractForms.select')"
              :imgName="'contracts.svg'"
            />
            <CustomInput
              :className="'col-md-6'"
              :id="'employeeWorkContractCode'"
              :value="employeeWorkContractData.employeeWorkContractCode"
              :title="$t('employeeWorkContracts.code')"
              :imgName="'number.svg'"
              v-on:changeValue="
                employeeWorkContractData.employeeWorkContractCode = $event
              "
            />
            <DatePicker
              :className="'col-md-6'"
              :id="'employeeWorkContractStartDate'"
              :value="employeeWorkContractData.employeeWorkContractStartDate"
              :title="$t('employeeWorkContracts.contractStartDate')"
              :language="language"
              v-on:changeValue="
                employeeWorkContractData.employeeWorkContractStartDate = $event
              "
            />
            <TimePicker
              :className="'col-md-6'"
              :id="'employeeWorkContractStartTime'"
              :value="employeeWorkContractData.employeeWorkContractStartTime"
              v-on:changeValue="
                employeeWorkContractData.employeeWorkContractStartTime = $event
              "
              :title="$t('employeeWorkContracts.contractStartTime')"
              :language="language"
            />
            <DatePicker
              :className="'col-md-6'"
              :id="'employeeWorkContractEndDate'"
              :value="employeeWorkContractData.employeeWorkContractEndDate"
              :title="$t('employeeWorkContracts.contractEndDate')"
              :language="language"
              v-on:changeValue="
                employeeWorkContractData.employeeWorkContractEndDate = $event
              "
            />
            <TimePicker
              :className="'col-md-6'"
              :id="'employeeWorkContractEndTime'"
              :value="employeeWorkContractData.employeeWorkContractEndTime"
              v-on:changeValue="
                employeeWorkContractData.employeeWorkContractEndTime = $event
              "
              :title="$t('employeeWorkContracts.contractEndTime')"
              :language="language"
            />
            <CustomInputFloat
              :className="'col-md-6'"
              :id="'employeeWorkContractSalary'"
              :value="employeeWorkContractData.employeeWorkContractSalary"
              :title="$t('employeeWorkContracts.contractSalary')"
              :imgName="'money.svg'"
              v-on:changeValue="
                employeeWorkContractData.employeeWorkContractSalary = $event
              "
            />
            <CustomInputInt
              :className="'col-md-6'"
              :id="'employeeWorkContractCountDaysHolidays'"
              :value="
                employeeWorkContractData.employeeWorkContractCountDaysHolidays
              "
              :title="$t('employeeWorkContracts.countDaysHolidays')"
              :imgName="'number.svg'"
              v-on:changeValue="
                employeeWorkContractData.employeeWorkContractCountDaysHolidays =
                  $event
              "
            />

            <CustomCheckbox
              :className="'col-md-12'"
              :value="employeeWorkContractData.employeeNationalNumberStatus"
              :title="$t('employeeWorkContracts.nationalNumberStatus')"
              v-on:changeValue="
                employeeWorkContractData.employeeNationalNumberStatus = $event
              "
            />
            <template
              v-if="employeeWorkContractData.employeeNationalNumberStatus"
            >
              <CustomInput
                :className="'col-md-4'"
                :id="'employeeNationalNumberIssuingAuthorityAr'"
                :value="
                  employeeWorkContractData.employeeNationalNumberIssuingAuthorityAr
                "
                :title="
                  $t('employeeWorkContracts.nationalNumberIssuingAuthorityAr')
                "
                :imgName="'nationalNumber.svg'"
                v-on:changeValue="
                  employeeWorkContractData.employeeNationalNumberIssuingAuthorityAr =
                    $event
                "
              />
              <CustomInput
                :className="'col-md-4'"
                :id="'employeeNationalNumberIssuingAuthorityEn'"
                :value="
                  employeeWorkContractData.employeeNationalNumberIssuingAuthorityEn
                "
                :title="
                  $t('employeeWorkContracts.nationalNumberIssuingAuthorityEn')
                "
                :imgName="'nationalNumber.svg'"
                v-on:changeValue="
                  employeeWorkContractData.employeeNationalNumberIssuingAuthorityEn =
                    $event
                "
              />
              <CustomInput
                :className="'col-md-4'"
                :id="'employeeNationalNumberIssuingAuthorityUnd'"
                :value="
                  employeeWorkContractData.employeeNationalNumberIssuingAuthorityUnd
                "
                :title="
                  $t('employeeWorkContracts.nationalNumberIssuingAuthorityUnd')
                "
                :imgName="'nationalNumber.svg'"
                v-on:changeValue="
                  employeeWorkContractData.employeeNationalNumberIssuingAuthorityUnd =
                    $event
                "
              />
              <DatePicker
                :className="'col-md-6'"
                :id="'employeeIssuanceDateNationalNumber'"
                :value="
                  employeeWorkContractData.employeeIssuanceDateNationalNumber
                "
                :title="$t('employeeWorkContracts.nationalNumberIssuingDate')"
                :language="language"
                v-on:changeValue="
                  employeeWorkContractData.employeeIssuanceDateNationalNumber =
                    $event
                "
              />
              <TimePicker
                :className="'col-md-6'"
                :value="
                  employeeWorkContractData.employeeIssuanceTimeNationalNumber
                "
                v-on:changeValue="
                  employeeWorkContractData.employeeIssuanceTimeNationalNumber =
                    $event
                "
                :title="$t('employeeWorkContracts.nationalNumberIssuingTime')"
                :language="language"
              />
              <DatePicker
                :className="'col-md-6'"
                :id="'employeeExpiryDateNationalNumber'"
                :value="
                  employeeWorkContractData.employeeExpiryDateNationalNumber
                "
                :title="$t('employeeWorkContracts.nationalNumberExpiryDate')"
                :language="language"
                v-on:changeValue="
                  employeeWorkContractData.employeeExpiryDateNationalNumber =
                    $event
                "
              />
              <TimePicker
                :className="'col-md-6'"
                :id="'employeeExpiryTimeNationalNumber'"
                :value="
                  employeeWorkContractData.employeeExpiryTimeNationalNumber
                "
                v-on:changeValue="
                  employeeWorkContractData.employeeExpiryTimeNationalNumber =
                    $event
                "
                :title="$t('employeeWorkContracts.nationalNumberExpiryTime')"
                :language="language"
              />
            </template>

            <CustomCheckbox
              :className="'col-md-12'"
              :value="employeeWorkContractData.employeePassportNumberStatus"
              :title="$t('employeeWorkContracts.passportNumberStatus')"
              v-on:changeValue="
                employeeWorkContractData.employeePassportNumberStatus = $event
              "
            />
            <template
              v-if="employeeWorkContractData.employeePassportNumberStatus"
            >
              <CustomInput
                :className="'col-md-4'"
                :id="'employeePassportNumberIssuingAuthorityAr'"
                :value="
                  employeeWorkContractData.employeePassportNumberIssuingAuthorityAr
                "
                :title="
                  $t('employeeWorkContracts.passportNumberIssuingAuthorityAr')
                "
                :imgName="'passportNumber.svg'"
                v-on:changeValue="
                  employeeWorkContractData.employeePassportNumberIssuingAuthorityAr =
                    $event
                "
              />
              <CustomInput
                :className="'col-md-4'"
                :id="'employeePassportNumberIssuingAuthorityEn'"
                :value="
                  employeeWorkContractData.employeePassportNumberIssuingAuthorityEn
                "
                :title="
                  $t('employeeWorkContracts.passportNumberIssuingAuthorityEn')
                "
                :imgName="'passportNumber.svg'"
                v-on:changeValue="
                  employeeWorkContractData.employeePassportNumberIssuingAuthorityEn =
                    $event
                "
              />
              <CustomInput
                :className="'col-md-4'"
                :id="'employeePassportNumberIssuingAuthorityUnd'"
                :value="
                  employeeWorkContractData.employeePassportNumberIssuingAuthorityUnd
                "
                :title="
                  $t('employeeWorkContracts.passportNumberIssuingAuthorityUnd')
                "
                :imgName="'passportNumber.svg'"
                v-on:changeValue="
                  employeeWorkContractData.employeePassportNumberIssuingAuthorityUnd =
                    $event
                "
              />
              <DatePicker
                :className="'col-md-6'"
                :id="'employeeIssuanceDatePassportNumber'"
                :value="
                  employeeWorkContractData.employeeIssuanceDatePassportNumber
                "
                :title="$t('employeeWorkContracts.passportNumberIssuingDate')"
                :language="language"
                v-on:changeValue="
                  employeeWorkContractData.employeeIssuanceDatePassportNumber =
                    $event
                "
              />
              <TimePicker
                :className="'col-md-6'"
                :id="'employeeIssuanceTimePassportNumber'"
                :value="
                  employeeWorkContractData.employeeIssuanceTimePassportNumber
                "
                v-on:changeValue="
                  employeeWorkContractData.employeeIssuanceTimePassportNumber =
                    $event
                "
                :title="$t('employeeWorkContracts.passportNumberIssuingTime')"
                :language="language"
              />
              <DatePicker
                :className="'col-md-6'"
                :id="'employeeExpiryDatePassportNumber'"
                :value="
                  employeeWorkContractData.employeeExpiryDatePassportNumber
                "
                :title="$t('employeeWorkContracts.passportNumberExpiryDate')"
                :language="language"
                v-on:changeValue="
                  employeeWorkContractData.employeeExpiryDatePassportNumber =
                    $event
                "
              />
              <TimePicker
                :className="'col-md-6'"
                :id="'employeeExpiryTimePassportNumber'"
                :value="
                  employeeWorkContractData.employeeExpiryTimePassportNumber
                "
                v-on:changeValue="
                  employeeWorkContractData.employeeExpiryTimePassportNumber =
                    $event
                "
                :title="$t('employeeWorkContracts.passportNumberExpiryTime')"
                :language="language"
              />
            </template>

            <CustomCheckbox
              :className="'col-md-12'"
              :value="employeeWorkContractData.employeeAddressStatus"
              :title="$t('employeeWorkContracts.addressStatus')"
              v-on:changeValue="
                employeeWorkContractData.employeeAddressStatus = $event
              "
            />
            <template v-if="employeeWorkContractData.employeeAddressStatus">
              <TextArea
                :className="'col-md-12'"
                :id="'employeeAddressAr'"
                :value="employeeWorkContractData.employeeAddressAr"
                :title="$t('employeeWorkContracts.addressAr')"
                :imgName="'address.svg'"
                v-on:changeValue="
                  employeeWorkContractData.employeeAddressAr = $event
                "
              />
              <TextArea
                :className="'col-md-12'"
                :id="'employeeAddressEn'"
                :value="employeeWorkContractData.employeeAddressEn"
                :title="$t('employeeWorkContracts.addressEn')"
                :imgName="'address.svg'"
                v-on:changeValue="
                  employeeWorkContractData.employeeAddressAr = $event
                "
              />
              <TextArea
                :className="'col-md-12'"
                :id="'employeeAddressUnd'"
                :value="employeeWorkContractData.employeeAddressUnd"
                :title="$t('employeeWorkContracts.addressUnd')"
                :imgName="'address.svg'"
                v-on:changeValue="
                  employeeWorkContractData.employeeAddressUnd = $event
                "
              />
            </template>
          </div>
        </div>
      </div>

      <div class="form-footer">
        <button
          name="submit"
          type="submit"
          class="btn btn-submit"
          @click.prevent="addOrUpdateEmployeeWorkContract"
        >
          {{ submitName }}
        </button>
        <router-link
          :to="{
            name: 'EmployeeWorkContracts',
          }"
          class="btn btn-cancel"
        >
          {{ $t("cancel") }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { STATUS } from "@/utils/constants";
import Employees from "@/models/employees/employees/Employees";
import Jobs from "@/models/settings/settingsOther/jobs/Jobs";
import ContractForms from "@/models/settings/settingsSalary/contractForms/ContractForms";
import createToastMixin from "@/utils/create-toast-mixin";
import currentTabMixin from "@/utils/currentTab-mixin";
import TextArea from "@/components/general/TextArea.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import CustomInputFloat from "@/components/general/CustomInputFloat.vue";
import CustomInputInt from "@/components/general/CustomInputInt.vue";
import DatePicker from "@/components/general/DatePicker.vue";
import TimePicker from "@/components/general/TimePicker.vue";
import CustomCheckbox from "@/components/general/CustomCheckbox.vue";

export default {
  name: "EmployeeWorkContractForm",
  mixins: [createToastMixin, currentTabMixin],
  components: {
    TextArea,
    CustomSelectBox,
    CustomInput,
    CustomInputFloat,
    CustomInputInt,
    DatePicker,
    TimePicker,
    CustomCheckbox,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken,
      language: localStorage.getItem("userLanguage") || "ar",
      employees: new Employees(),
      employeeTokenOptions: [],
      jobs: new Jobs(),
      jobTokenOptions: [],
      contractForms: new ContractForms(),
      contractFormTokenOptions: [],
    };
  },
  props: ["employeeWorkContractData", "submitName"],
  methods: {
    async getDialogOfEmployees() {
      this.isLoading = true;
      this.employeeTokenOptions = [];
      this.employeeTokenOptions.push({
        value: "",
        text: this.$t("selectEmployee"),
      });
      try {
        const response = await this.employees.employee.getDialogOfEmployees(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.employeeTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfJobs() {
      this.isLoading = true;
      this.jobTokenOptions = [];
      try {
        let response = await this.jobs.job.getDialogOfJobs(
          this.language,
          this.userAuthorizeToken
        );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.jobTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async getDialogOfContractForms() {
      this.isLoading = true;
      this.contractFormTokenOptions = [];
      try {
        const response =
          await this.contractForms.contractForm.getDialogOfContractForms(
            this.language,
            this.userAuthorizeToken
          );
        const itemsData = response.data.itemsData;
        if (response.data.status == STATUS.SUCCESS) {
          for (let item in itemsData) {
            this.contractFormTokenOptions.push({
              value: itemsData[item]["itemToken"],
              text: itemsData[item]["itemName"],
            });
          }
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        }
        this.isLoading = false;
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async addOrUpdateEmployeeWorkContract() {
      this.$emit("addOrUpdateEmployeeWorkContract");
    },
  },
  async created() {
    this.getDialogOfEmployees();
    this.getDialogOfJobs();
    this.getDialogOfContractForms();
  },
};
</script>

<style scoped lang="scss"></style>
