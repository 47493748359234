<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <EmployeeWorkContractForm
      v-if="!isLoading"
      :employeeWorkContractData="employeeWorkContract"
      :currentTab="currentTab"
      :submitName="$t('add')"
      v-on:addOrUpdateEmployeeWorkContract="addOrUpdateEmployeeWorkContract()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EmployeeWorkContractForm from "@/components/employees/employeeWorkContracts/EmployeeWorkContractForm.vue";
import createToastMixin from "@/utils/create-toast-mixin";
import currentTabMixin from "@/utils/currentTab-mixin";
import EmployeeWorkContract from "@/models/employees/employeeWorkContracts/EmployeeWorkContract";

export default {
  name: "EmployeeWorkContractAdd",
  mixins: [createToastMixin, currentTabMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeWorkContractForm,
  },
  computed: {
    ...mapGetters(["employeeToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employeeWorkContract: new EmployeeWorkContract(),
    };
  },
  methods: {
    async addOrUpdateEmployeeWorkContract() {
      this.isLoading = true;
      try {
        let response =
          await this.employeeWorkContract.addOrUpdateEmployeeWorkContract(
            this.language,
            this.userAuthorizeToken
          );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.employeeWorkContract.setInitialValue();
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    if (this.currentTab && this.employeeToken) {
      this.employeeWorkContract.employeeToken = this.employeeToken;
    }
  },
};
</script>
